.block.follow_us {
  max-width: var(--narrow-container-width);
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: $largest-mobile-screen) {
    padding: 0 20px;
  }

  .social-network {
    &::after {
      display: none !important;
    }
  }
}
