// Hide existing h1

#main #page-document {
  > h1.documentFirstHeading {
    display: none;
  }
}

body.section-search {
  #main .breadcrumbs-title-wrapper {
    display: none;
  }
}

#main .breadcrumbs-title-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  background-color: $container-background;

  .title-wrapper {
    max-width: var(--default-container-width);
    margin-right: auto;
    margin-left: auto;
    @media only screen and (max-width: $largest-mobile-screen) {
      padding-left: 1.5rem;
    }

    h1.documentFirstHeading {
      border: none;
      margin-top: 3rem;
      margin-bottom: 0.5rem;
      font-size: 3rem;
      font-weight: 500;
      line-height: 3.2rem;
    }
  }

  .breadcrumbs {
    padding: 10px 0;
    background-color: $container-background;

    .breadcrumb {
      a {
        color: $link-color !important;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

body.contenttype-plone-site #main .breadcrumbs-title-wrapper,
body.contenttype-lrf #main .breadcrumbs-title-wrapper {
  display: none;
}
