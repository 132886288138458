// Grid
.block.listing.grid .listing-item .card-container,
.column > .block.listing.grid .listing-item .card-container,
.block.search.grid .listing-item .card-container,
.block-editor-search.grid .listing-item {
  .card-container {
    background-color: $container-background;
  }
}
/// Post
.block.listing.grid .listing-item .card-container.post,
.column > .block.listing.grid .listing-item .card-container.post,
.block.search.grid .listing-item .card-container.post,
.block-editor-search.grid .listing-item .card-container.post {
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: $container-background;

  .item-image-box {
    overflow: hidden;
    border-radius: 4px;

    .item-image {
      width: 100%;
      max-width: 100%;
      aspect-ratio: $teaser-images-aspect-ratio !important;
      object-fit: cover;
      -webkit-transform: scale(1) rotate(0);
      -ms-transform: scale(1) rotate(0);
      transform: scale(1) rotate(0);
      -webkit-transition: all 0.3s ease-in-out;
      -moz-transition: all 0.3s ease-in-out;
      -ms-transition: all 0.3s ease-in-out;
      -o-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out;

      &:hover {
        -webkit-transform: scale(1.1) rotate(2deg);
        -ms-transform: scale(1.1) rotate(2deg);
        transform: scale(1.1) rotate(2deg);
      }
    }
  }

  .item {
    padding-bottom: 1rem;
    margin-top: 5px;

    .meta {
      display: flex;
      padding: 0.3rem 0;
      color: $meta-color;
      font-size: 14px;
      font-weight: 500;

      .meta-wrapper {
        display: flex;
        margin-right: auto;
        margin-left: auto;
      }

      .icon {
        height: 20px !important;
        color: $meta-color;
      }

      .effective {
        display: flex;
        align-items: center;
      }

      .tags {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .ui.tag {
          margin-left: auto !important;
        }

        .divider {
          margin-right: 5px;
        }
      }
    }

    .content {
      text-align: center;
    }
  }
}

// Image Gallery
#main .block.listing.image_grid {
  .image-listing-grid {
    display: flex;
    max-width: var(--default-container-width);
    flex-wrap: wrap;
    margin-right: auto;
    margin-left: auto;

    .image-listing-item {
      height: 200px;
      flex-grow: 1;
      margin: 2px;
      container-type: normal;

      img {
        min-width: 100%;
        max-width: 100%;
        height: 200px;
        object-fit: cover;
        vertical-align: bottom;
      }
    }
  }
}

#main .block.listing:not(.has--headline) {
  margin-top: 1rem !important;
}
