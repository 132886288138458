// Blockquote
#page-document,
#page-edit {
  blockquote {
    padding: 20px 60px;
    margin-left: 10px;
    font-size: 1.5rem;
    font-style: italic;
    font-weight: 700;
    line-height: 1.6;

    &::before {
      padding-right: 10px;
      margin-left: -27px;
      color: #000;
      content: open-quote;
      font-size: 36px;
    }
  }
}
