.ui.basic.segment.content-area {
  // We cancel the padding and margin from the segment
  // allowing the content elements to be the ones that push for
  // the required vertical spacing
  padding: 0;
  margin-top: 0;
}

.contenttype-news-item {
  .documentFirstHeading {
    margin-top: 0px;
  }
  .blocks-group-wrapper {
    padding-top: 0px;
  }
  #page-document .dates {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
    .day {
      @include body-text-bold();
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    @container (min-width: #{$tablet-breakpoint} ) {
      .day + .head-title::before {
        content: '|';
      }
    }

    @container (max-width: #{$largest-mobile-screen} ) {
      .day {
        display: none;
      }
    }
  }
}

// Event content type

.contenttype-event {
  // Doing this because of semantic ui react important mark
  @container (max-width: #{$largest-mobile-screen} ) {
    .ui.stackable.grid.details-container {
      margin-right: $spacing-small !important;
      margin-left: $spacing-small !important;
    }
    .ui.grid.details-container .row .column {
      padding: 0px !important;
    }
  }

  .ui.grid.details-container .row .column {
    padding: 0px;
  }

  .documentFirstHeading {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .blocks-group-wrapper {
    padding-top: 0px;
    padding-bottom: 60px;
  }
  #page-document {
    .dates,
    .details-container {
      @include default-container-width();
      @include adjustMarginsToContainer($default-container-width);
    }
  }
  .dates {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;

    .day {
      @include body-text-bold();
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .details-container {
    margin-bottom: 50px;
    .two.column.row {
      .event-title {
        margin-bottom: 25px;
        .event-heading {
          @include headtitle2();
          letter-spacing: 1px;
          text-transform: uppercase;
        }
        .event-detail {
          margin-top: 10px;
          font-size: 24px;
          font-weight: 300;
          line-height: 33px;
          p,
          a {
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 300;
            line-height: 33px;
          }
        }
        .separator {
          width: 170px;
          height: 30px;
          border-right: none;
          border-bottom: 1px solid #000;
        }
      }

      .event-button {
        padding-top: 50px;
        a:after {
          display: none !important;
        }
        .button.event-btn {
          padding: 8px 20px;
          border: 1px solid #000;
          background-color: transparent;
          color: #000;
          font-size: 16px;
          font-weight: 900;
          line-height: 20px;
          a {
            color: #000;
            font-size: 16px;
            font-weight: 900;
            line-height: 20px;
          }
        }
      }
    }
  }
}

.contenttype-file {
  .content-area {
    a {
      text-decoration: underline;
    }
    span {
      color: #808080;
    }
  }
}
.contenttype-image {
  .content-area {
    figure {
      img {
        aspect-ratio: $aspect-ratio;
      }
      figcaption {
        margin-top: $spacing-small;
        margin-bottom: 0;
        .title {
          margin-bottom: 5px;
        }
        .description div {
          margin-bottom: 5px;
        }
        .credits {
          padding-bottom: $spacing-small;
          margin-bottom: 0;
          color: #0066cc;
        }
      }
    }
  }
}

.contenttype-link,
.contenttype-file,
.contenttype-image {
  #page-document > *,
  #page-edit > *,
  #page-add > * {
    @include default-container-width();
    @include adjustMarginsToContainer($default-container-width);
  }

  #page-document > p {
    @include narrow-container-width();
    @include adjustMarginsToContainer($narrow-container-width);
  }
  .documentDescription {
    margin-bottom: $spacing-medium;
    color: $black;
    @include body-text();

    @media only screen and (max-width: $largest-mobile-screen) {
      margin-bottom: $spacing-large;
    }
  }
}

/* maps */
.block.maps iframe,
.block.align.right.maps iframe,
.block.align.left.maps iframe {
  height: unset;
  aspect-ratio: $aspect-ratio;
}

// Slate Style External Links
.content-area .ui.container,
.content-area .a.container {
  a[href^="http://"]:not([href^="http://light-theme"]):not([href^="http://light-theme.de"]):not([href^="http://www.light-theme.de"]):after,
  a[href^="https://"]:not([href^="https://light-theme"]):not([href^="https://light-theme.de"]):not([href^="https://www.light-theme.de"]):after
  {
    display: inline;
    width: 11px;
    height: 11px;
    margin-top: -25px;
    margin-left: 3px;
    content: '\a0'url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='external-link-alt' class='svg-inline--fa fa-external-link-alt fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 512 512'%3E%3Cpath fill='%23007EB1' d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'%3E%3C/path%3E%3C/svg%3E");
    white-space: nowrap;
  }
}
.content-area #page-edit .ui.container {
  a[href^="http://"]:not([href^="http://light-theme"]):not([href^="http://light-theme.de"]):not([href^="http://www.light-theme.de"]):after,
  a[href^="https://"]:not([href^="https://light-theme"]):not([href^="https://light-theme.de"]):not([href^="https://www.light-theme.de"]):after
  {
    display: inline;
    width: 11px;
    height: 11px;
    margin-top: -25px;
    margin-left: 3px;
    content: '\a0'url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='external-link-alt' class='svg-inline--fa fa-external-link-alt fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' width='11' height='11' viewBox='0 0 512 512'%3E%3Cpath fill='%23007EB1' d='M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z'%3E%3C/path%3E%3C/svg%3E");
    white-space: nowrap;
  }
}

// hide some fields from edit & add forms
.field-wrapper-changeNote {
  display: none;
}
