body.section-sketches {
  #page-document .blocks-group-wrapper > .block.listing.grid {
    max-width: var(--narrow-container-width);
  }
  @media only screen and (min-width: 769px) {
    .block.listing.grid .listing-item,
    .column > .block.listing.grid .listing-item,
    .block.search.grid .listing-item,
    .block-editor-search.grid .listing-item {
      @media only screen and (min-width: $tablet-breakpoint) {
        width: 25%;
        padding-top: 0px !important;
        padding-bottom: 10px !important;
        padding-left: 10px !important;

        .card-container {
          margin-top: 0px !important;
          margin-right: 0px !important;
          margin-left: 0px !important;
        }

        &:nth-child(2n + 1) &:nth-child(2n),
        &:nth-child(3n),
        &:nth-child(4n) {
          padding-left: 10px !important;
        }

        &:nth-child(2n + 1),
        &:nth-child(3n),
        &:nth-child(4n + 1) {
          padding-right: 10px !important;
        }

        &:nth-child(2n),
        &:nth-child(3n) {
          padding-right: 10px !important;
        }

        &:nth-child(4n + 1) {
          padding-right: 10px !important;
        }

        &:last-child,
        &:nth-last-child(4):not(:nth-child(4n)) {
          padding-bottom: 0 !important;
        }

        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          padding-top: 0 !important;
        }
      }

      &:last-child:nth-child(2n + 1) {
        @media only screen and (min-width: $largest-mobile-screen) {
          margin-left: 0 !important;
        }
      }

      &:last-child:nth-child(4n + 1) {
        @media only screen and (min-width: $largest-mobile-screen) {
          margin-left: 0 !important;
        }
      }
      .item-image {
        object-fit: cover !important;
      }
      .item > .content {
        text-align: center;
      }
    }
  }
}
