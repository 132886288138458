#page-document .block.separator {
  // cancel the original padding from the add-on
  &:not(.inner):not([role='presentation']) {
    padding: 0 !important;
  }

  &.has--align--center,
  &.inner {
    margin-right: auto;
    margin-left: auto;
  }

  @include vertical-space-separator();
}

.block.image + .block.separator {
  margin-top: 60px !important;
}

#page-edit .block-editor-separator {
  &.has--backgroundColor--grey {
    background-color: $lightgrey;
  }
  .block.separator {
    &.inner {
      margin-right: auto;
      margin-left: auto;

      .block.separator {
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
