:root {
  --social-network-icon-color-main: #000;
  --social-network-icon-color-hover: #000;
  --teaser-images-aspect-ratio: #{$teaser-images-aspect-ratio};
  --narrow-container-width: 940px;
  --default-container-width: 1240px;
  --layout-container-width: 1440px;
}

a {
  color: $link-color;
  text-decoration: solid;

  &:hover {
    color: $link-color-hover;
    text-decoration: solid;
  }
}

#page-controlpanel-layout {
  max-width: var(--default-container-width);
  margin-right: auto;
  margin-left: auto;
}
