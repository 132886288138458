#main .layout.documentByLine {
  max-width: var(--default-container-width);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid $meta-color;
  margin-right: auto;
  margin-left: auto;
  color: $meta-color;
  @media only screen and (max-width: $largest-mobile-screen) {
    margin-right: 20px;
    margin-left: 20px;
  }

  .a.container.info {
    display: inline;
    font-size: small;

    &.author {
      .name {
        font-size: small;
      }
    }

    :after {
      padding-right: 0.5em;
      padding-left: 0.5em;
      content: ' • ';
    }

    &:last-child {
      :after {
        padding-right: 0;
        padding-left: 0;
        content: '';
      }
    }
  }
  @media only screen and (max-width: $largest-mobile-screen) {
    .ui.container.documentByLine {
      margin-right: 2rem !important;
      margin-left: 2rem !important;
    }

    .ui.container.info {
      margin-right: 5px !important;
      margin-left: 0px !important;

      :after {
        padding-right: 0.1em;
        padding-left: 0.1em;
      }

      &.effective {
        display: none;
      }
    }
  }
}
