.block.separator .line::after {
  position: relative;
  top: 50%;
  display: block;
  width: 85%;
  border-top: 1px solid $meta-color;
  margin-top: -1px;
  margin-right: auto;
  margin-left: auto;
  content: '';
}
