body.contenttype-sketch #main {
  .sketch.image {
    max-width: var(--narrow-container-width);
    margin-right: auto;
    margin-left: auto;
    @media only screen and (max-width: $largest-mobile-screen) {
      margin-right: 20px;
      margin-left: 20px;
    }

    img {
      width: auto;
      height: auto;
    }
  }
}
