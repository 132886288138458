@use 'sass:math';

$meta-color: #757575;
$container-background: #ecebeb;
$link-color: #000;
$link-color-hover: #000;

// Images
// Image Aspect Ratio
$aspect-ratio: math.div(16, 9) !default;
$teaser-images-aspect-ratio: math.div(16, 9) !default;
